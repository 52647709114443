<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authFackMethods } from "@/state/helpers";
/**
 * Advanced-table component
 */
export default {
  page: {
    title: "Customer List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      loading: false,
      title: "Customer Management",
      items: [
        {
          text: "Home",
          href: "/"
        },
        {
          text: "Customer List",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "name",
      sortDesc: true,
      fields: [
        // { index: 'No' },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "idno",
          sortable: true,
        },
        {
          key: "contact",
          sortable: true,
        },
        {
          key: "remark",
          // sortable: true,
        },
        {
          key: "created",
          sortable: true
        },
        {
          key: "updated",
          sortable: true
        },
        {
          key: "status",
          sortable: true,
        },
        {
          key: 'operation'
        }
      ],
      customer_list: []
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.customer_list.length;
    },
    compute_user(){
      return this.$store.state.authfack.user
    },
    lock_viewBtn(){
      if(this.compute_user.permissions.includes('*') || this.compute_user.permissions.includes('032') || this.compute_user.permissions.includes('034')){
        return false
      }
      else{
        return true
      }
    },
  },
  mounted() {
    // Set the initial number of items
    if(this.compute_user.permissions.includes('*') || this.compute_user.permissions.includes('035')){
      this.loading = true
      const baseURI = 'customers/list'
      let formData = new FormData();
      formData.append('val', '');
      formData.append('status', 'ACTIVE,INACTIVE,DELETED');

      this.$http.post(baseURI,formData)
      .then((result) => {
        if(result.data.status == 'success'){
          this.customer_list = result.data.data

          this.loading = false
        }
      })
      .catch((err) => {
        if(err == 'session_timeout'){
          this.$notify({
            group: 'auth',
            title: 'User Authentication',
            text: 'Your session has expired, please login again',
            type: 'warn'
          });
          this.logout();
          this.$router.push({
            path: "/login",
          });
        }
      })
    }
    else{
      this.$router.push({
        name: 'error-page'
      });
    }
  },
  methods: {
    ...authFackMethods,
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    add(){
      this.$router.push("/customer/create");
    },
    view(customer_id){
      this.$router.push(`/customer/${customer_id}`);
    }
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
      <b-overlay :show="loading" rounded="sm">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Customer List
              <div style="float:right"><b-button size="sm" variant="primary" @click="add">Add Customer<i class="uil uil-plus ms-2"></i></b-button></div>
            </h4>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="customer_list"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(created)="data">
                  {{ moment(data.item.created_at).format('YYYY-MM-DD HH:mm') }}
                </template>
                <template #cell(updated)="data">
                  {{ moment(data.item.updated_at).format('YYYY-MM-DD HH:mm') }}
                </template>

                <!-- A virtual composite column -->
                <template #cell(operation)="data">
                  <a v-show="data.item.status !== 'DELETED' && !lock_viewBtn"
                    href="javascript:void(0);"
                    class="px-2 text-primary"
                    v-b-tooltip.hover
                    title="View"
                    @click="view(data.item.uuid)"
                  >
                    <i class="uil uil-pen font-size-18"></i>
                  </a>

                  <!-- <b-button v-show="data.item.status !== 'DELETED' " size="sm" variant="primary" @click="view(data.item.uuid)">View</b-button> -->
                  <span v-show="data.item.status == 'DELETED' " class="text-muted"> No action </span>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-start"
                >
                  <p><b>Total : {{rows}}</b></p>
                </div>
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-overlay>
      </div>
    </div>
  </Layout>
</template>
